import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey
} from 'constants/defaultValues';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    // console.log(
    //   '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
    //   error
    // );
    currentRadius = 'rounded';
  }
  return currentRadius;
};

export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    // console.log(
    //   '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
    //   error
    // );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    // console.log(
    //   '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
    //   error
    // );
    language = defaultLocale;
  }
  return language;
};

export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    // console.log(
    //   '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
    //   error
    // );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('explorant_current_user') != null
        ? JSON.parse(localStorage.getItem('explorant_current_user'))
        : null;
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const getCurrentEmail = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('explorant_current_user') != null
        ? JSON.parse(localStorage.getItem('explorant_current_user'))
        : null;
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user?.username ?? user?.email ?? 'Explorant User';
};

export const getUserAbilities = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('explorant_current_user') != null
        ? JSON.parse(localStorage.getItem('explorant_current_user'))
        : null;
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user?.abilities ?? [];
};

export const getCurrentToken = () => {
  let token = null;
  try {
    token = localStorage.getItem('explorant_current_user_token');
  } catch (error) {
    // console.log(
    //   '>>>>: src/helpers/Utils.js  : getCurrentToken -> error',
    //   error
    // );
    token = null;
  }
  return token;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('explorant_current_user', JSON.stringify(user));
      localStorage.setItem('explorant_current_user_token', user.token);
    } else {
      localStorage.removeItem('explorant_current_user');
      localStorage.removeItem('explorant_current_user_token');
    }
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getListingCount = (type) => {
  let numberOfAccounts = 8;
  try {
    if (localStorage.getItem(`explorant_${type}_listing_count`)) {
      numberOfAccounts = localStorage.getItem(
        `explorant_${type}_listing_count`
      );
    }
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : getListingCount -> error', error);
    numberOfAccounts = 8;
  }
  return numberOfAccounts;
};

export const setListingCount = (type, count) => {
  try {
    localStorage.setItem(`explorant_${type}_listing_count`, count);
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : setListingCount -> error', error);
  }
};

export const getListingOrder = (type) => {
  let listingOrder = 'id-desc';
  try {
    if (localStorage.getItem(`explorant_${type}_listing_order`)) {
      listingOrder = localStorage.getItem(`explorant_${type}_listing_order`);
    }
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : getListingOrder -> error', error);
    listingOrder = 'id-desc';
  }
  return listingOrder;
};

export const setListingOrder = (type, order) => {
  try {
    localStorage.setItem(`explorant_${type}_listing_order`, order);
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : setListingOrder -> error', error);
  }
};
export const getListingMode = (type) => {
  let listingMode = 'imagelist';
  try {
    if (localStorage.getItem(`explorant_${type}_listing_mode`)) {
      listingMode = localStorage.getItem(`explorant_${type}_listing_mode`);
    }
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : getListingMode -> error', error);
    listingMode = 'imagelist';
  }
  return listingMode;
};

export const setListingMode = (type, mode) => {
  try {
    localStorage.setItem(`explorant_${type}_listing_mode`, mode);
  } catch (error) {
    // console.log('>>>>: src/helpers/Utils.js : setListingMode -> error', error);
  }
};

export const wait = (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};
