import axios from 'axios';
import { setCurrentUser } from 'helpers/Utils';
import { APP_ENV } from './environment';

export const UserRole = {
  Admin: 0,
  Editor: 1
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' }
  // { id: 'es', name: 'Español', direction: 'ltr' },
  // { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const adminRoot = '/dashboard';
export const buyUrl = 'https://explorant.space';
export const discordUrl = 'https://discord.gg/xuQU7tv3mX';
export const youtubeUrl =
  'https://www.youtube.com/channel/UC7WKTV1-vsSMQh6qTjMNdFA';
export const twitterUrl = 'https://twitter.com/TheExplorant';
export const facebookUrl = 'https://facebook.com/TheExplorant';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.explorant.space/api/v1/';

export const landingUrl = 'https://explorant.space';

export const apiUrl =
  APP_ENV === 'production'
    ? 'https://api.explorant.space/api/v1/'
    : 'http://192.168.100.201:8000/api/v1/';

export const previewUrl =
  APP_ENV === 'production'
    ? 'https://preview.explorant.space/account'
    : 'http://localhost:8001/account';

axios.defaults.baseURL = apiUrl;

export const axiosRequest = async (url, config) => {
  try {
    const axiosResponse = await axios
      .request({
        url,
        ...config
      })
      .then((response) => response.data)
      .catch((e) => {
        const { success, message, reauth } = e.response.data;
        if (message === 'Unauthenticated.' || e.response.status === 401) {
          setCurrentUser();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        return { success, message, data: [], reauth: reauth ?? false };
      });

    return axiosResponse;
  } catch (e) {
    return {
      success: false,
      message: 'Something went wrong',
      data: []
    };
  }
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'dark.redruby';
export const isDarkSwitchActive = false;
export const isSearchActive = false;
export const defaultDirection = 'ltr';
export const isEaseOfAccessActive = false;
export const isNotificationsActive = false;
export const isSubMenuActive = false;
export const isErrorRedirectAllowed = true;
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel'
];
export const SITE_KEY = '6LdXZnIkAAAAAD8kndmhpOIMYNsOAcIWgyA6kNca';

export const defaultAbilities = [
  'dummy-ability',
  'account-crud',
  'account-details',
  'account-store',
  'account-inventory',
  'account-challenges',
  'account-links',
  'account-slot-10',
  'cooldown-24'
];
