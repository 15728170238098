//  --PLATFORM ENVS
//  local
//  production
export const APP_ENV = 'production';
//  --PLATFORM ENVS
//  landing
//  application
export const PLATFORM_ENV = 'landing';

export const DASHBOARD_URL = 'https://app.explorant.space';
