import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { adminRoot } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  VERIFY_USER
  // ROUTE_CHANGE,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  verifyUserSuccess,
  verifyUserError
  // routeChangedSuccess,
} from './actions';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  return axios
    .post('login', {
      email,
      password
    })
    .then((response) => {
      const { success, message, data } = response.data;
      return {
        success,
        message,
        user: data
      };
    })
    .catch((e) => {
      const { success, message } = e.response.data;
      return {
        success,
        message
      };
    });
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginResponse = yield call(
      loginWithEmailPasswordAsync,
      email,
      password
    );
    if (loginResponse.success) {
      const item = { uid: loginResponse.user.id, ...loginResponse.user };
      setCurrentUser(item);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginResponse.message));
    }
  } catch (e) {
    yield put(loginUserError('Something went wrong'));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (
  email,
  password,
  confirmPassword,
  token
) => {
  return axios
    .post('register', {
      name: email,
      email,
      password,
      password_confirmation: confirmPassword,
      token
    })
    .then((response) => {
      const { success, message, data } = response.data;
      return {
        success,
        message,
        user: data
      };
    })
    .catch((e) => {
      const { success, message } = e.response.data;
      return {
        success,
        message
      };
    });
};

function* registerWithEmailPassword({ payload }) {
  const { email, password, confirmPassword, token } = payload.user;
  // const { history } = payload;
  try {
    const registerResponse = yield call(
      registerWithEmailPasswordAsync,
      email,
      password,
      confirmPassword,
      token
    );
    if (registerResponse.success) {
      // const item = { uid: registerUser.user.uid, ...currentUser };
      // setCurrentUser(item);
      yield put(registerUserSuccess(true));
      // history.push(`${adminRoot}/auth/login`);
    } else {
      yield put(registerUserError(registerResponse.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return axios
    .post('forgot-password', {
      email
    })
    .then((response) => {
      const { success, message, data } = response.data;
      return {
        success,
        message,
        user: data
      };
    })
    .catch((e) => {
      const { success, message } = e.response.data;
      return {
        success,
        message
      };
    });
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordResponse = yield call(forgotPasswordAsync, email);
    if (forgotPasswordResponse.success) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordResponse.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (
  newPassword,
  newPasswordAgain,
  email,
  token
) => {
  // eslint-disable-next-line no-return-await
  return axios
    .post('reset-password', {
      token,
      email,
      password: newPassword,
      password_confirmation: newPasswordAgain
    })
    .then((response) => {
      const { success, message, data } = response.data;
      return {
        success,
        message,
        user: data
      };
    })
    .catch((e) => {
      const { success, message } = e.response.data;
      return {
        success,
        message
      };
    });
};

function* resetPassword({ payload }) {
  const { newPassword, newPasswordAgain, email, token } = payload;
  try {
    const resetPasswordResponse = yield call(
      resetPasswordAsync,
      newPassword,
      newPasswordAgain,
      email,
      token
    );
    if (resetPasswordResponse.success) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordResponse.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchVerifyUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(VERIFY_USER, verifyUser);
}

const verifyUserAsync = async (url) => {
  // eslint-disable-next-line no-return-await
  const decoded = decodeURIComponent(url);
  return axios
    .post(decoded)
    .then((response) => {
      const { success, message, data } = response.data;
      return {
        success,
        message,
        user: data
      };
    })
    .catch((e) => {
      const { success, message } = e.response.data;
      return {
        success,
        message
      };
    });
};

function* verifyUser({ payload }) {
  const { url } = payload;
  try {
    const verifyUserResponse = yield call(verifyUserAsync, url);
    if (verifyUserResponse.success) {
      yield put(verifyUserSuccess(true));
    } else {
      yield put(verifyUserError(verifyUserResponse.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

// export function* watchRouteChange() {
//   console.log(' DID I GET HERE');
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(ROUTE_CHANGE, routeChanged);
// }

// function* routeChanged() {
//   try {
//     console.log(' I GOT HERE');
//     yield put(routeChangedSuccess(true));
//   } catch (error) {
//     console.log('THERE IS AN ERROR');
//     yield put(forgotPasswordError(error));
//   }
// }

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchVerifyUser),
    fork(watchForgotPassword),
    fork(watchResetPassword)
    // fork(watchRouteChange),
  ]);
}
